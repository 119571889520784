jQuery(document).ready(function($){

    $('.about2__slider').slick({
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        responsive: [
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true,
                }
            }
        ],
    });

    $('.linia1__slider').slick({
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1
                }
            }
        ],
    });

    $('.home2__slider').slick({
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true,
                }
            }
        ],
    });

    $('.home1__slider').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: true,
        fade: true,
        prevArrow: '<button type="button" class="slick-prev">&nbsp;</button>',
        nextArrow: '<button type="button" class="slick-next">&nbsp;</button>',
    });

});
